module.exports = [{
      plugin: require('/tmp/1a6bdb7e/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/tmp/1a6bdb7e/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Open Sans"]}},
    },{
      plugin: require('/tmp/1a6bdb7e/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-101953660-1","respectDNT":true},
    },{
      plugin: require('/tmp/1a6bdb7e/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
